import React from 'react'
import './TextField.css'

const TextContato = (props) => {

    return(
        <div className="TextField">
            <div className="title">
                <h1 className="contato">{props.title} <span>{props.title2}</span></h1>
            </div>
            <div className="content">
                <div className="text">
                    {props.children}
                </div>
                <div className={"imgText " + props.title}>
                    {props.forms}
                </div>
            </div>
        </div>
    )
}

export default TextContato