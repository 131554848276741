import React from 'react'
import './SecondaryText.css'

const SecondaryText = (props) => {

    return(
        <div className="SecondaryText">
            <div className={"imgField img" + props.img}>
            </div>
            <div className="textField">
                <h1>{props.title}</h1>
                {props.children}
            </div>
        </div>
    )
}

export default SecondaryText