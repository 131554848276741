import React from 'react'
import './Home.css'
import Carousel from '../../components/Carousel'

const Home = (props) => {

    return(
        <section id="Home" className="Home">
            <Carousel />
           
        </section>
    )
}

export default Home