import React from 'react'
import './Form.css'
import emailjs from "emailjs-com"

const Trabalhe = (props) => {
    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('service_sotecplast', 'template_w8y7g5o', e.target, 'user_woYlHwkVMg8jcAkO7a6Vs')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

    return(
        <div className="Trabalhe">
            <h1>Trabalhe Conosco</h1>
            <div className="line"></div>
            <form onSubmit={sendEmail}>
                <label htmlFor="name">Nome</label>
                <input type="text" name="name" />
                <label htmlFor="name">Endereço</label>
                <input type="text" name="endereco" />
                <label htmlFor="name">CEP</label>
                <input type="text" name="cep" />
                <label htmlFor="name">Bairro</label>
                <input type="text" name="bairro" />
                <label htmlFor="name">Cidade</label>
                <input type="text" name="cidade" />
                <label htmlFor="email">Email</label>
                <input type="text" name="email" />
                <label htmlFor="phone">Telefone</label>
                <input type="text" name="phone" />
                <label htmlFor="subject">Escolaridade</label>
                <textarea name="escolaridade" id="" cols="30" rows="10" />
                <label htmlFor="name">Última empresa que trabalhou</label>
                <input type="text" name="ultimaempresa" />
                <label htmlFor="name">Cargo</label>
                <input type="text" name="cargo" />
                <label htmlFor="name">Data de Admissão</label>
                <input type="text" name="admissao" />
                <label htmlFor="name">Data de demissão</label>
                <input type="text" name="demissao" />
                <label htmlFor="subject">Motivo da demissão</label>
                <textarea name="motivodemissao" id="" cols="30" rows="10" />
                <label htmlFor="subject">Qual a sua disponibilidade para flexibilidade de horários de trabalho?</label>
                <textarea name="disponibilidade" id="" cols="30" rows="10" />
                <label htmlFor="subject">Por que devemos te contratar?</label>
                <textarea name="contratar" id="" cols="30" rows="10" />
                <button type="submit">
                    Enviar
                </button>
            </form>
        </div>
    )
}

export default Trabalhe