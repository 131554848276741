import React from 'react'
import './Topo.css'

const Topo = (props) => {

    return(
        <div className={'top ' + props.page}>
            <div className="content">
                <h2>{props.title}</h2>
                <div className="linha"></div>
                <p>{props.subtitle}</p>
            </div>
        </div>
    )
}

export default Topo