import React from 'react'
import TextField from '../../components/TextField'
import './Servicos.css'
import Topo from '../../components/Topo'

const Servicos = (props) => {

    return(
        <section id="Servicos" className="Servicos">
            <Topo
                page="servicos"
                          />
            <div className="atuacao">
            
            <TextField title="SEGMENTOS DE ATUAÇÃO" img="sustentabilidadeFoto"></TextField>
                <div className="areas">
               
                <div className="areaBox">
                    <div className="areaBox1">
                        <h5>Naval</h5></div>
                        </div>
                        <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                   
                        <div className="areaBox">
                    <div className="areaBox2">
                        <h5>Automobilístico</h5></div>
                        </div>
                        <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    
                    <div className="areaBox">
                    <div className="areaBox3">
                        <h5>Papel e celulose </h5></div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="areaBox">
                    <div className="areaBox4">
                        <h5>Portuário</h5></div>
                    </div>
                    
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    <div className="areaBox">
                    <div className="areaBox5">
                        <h5>Químico/ Petroquímico</h5></div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="areaBox">
                    <div className="areaBox6">
                        <h5>Alimentício</h5></div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="areaBox">
                    <div className="areaBox7">
                        <h5>Sucroenergético/ Agronegócio</h5></div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="areaBox">
                    <div className="areaBox8">
                        <h5>Off-shore</h5></div>
                        <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    </div>
                </div>
            </div>
            <div className="prestados">
                <h1>SERVIÇOS</h1>
                <div className="slider">
                    <div className="item item1">
                        <h5>PREPARAÇÃO DE SUPERFÍCIES</h5>
                    </div>
                    <div className="item item8">
                        <h5>REVESTIMENTO DE SUPERFÍCIES</h5>
                    </div>
                    <div className="item item2">
                        <h5>APOIO PORTUÁRIO</h5>
                    </div>
                    <div className="item item6">
                        <h5>SOLDA E CALDEIRARIA</h5>
                    </div>
                    <div className="item item11">
                        <h5>ELETROMECÂNICA</h5>
                    </div>
                    <div className="item item5">
                        <h5>FORNECIMENTO DE VEÍCULOS</h5>
                    </div>
                    <div className="item item10">
                        <h5>LIMPEZA TÉCNICA</h5>
                    </div>
                    <div className="item item4">
                        <h5>CONSERVAÇÃO DAS ÁREAS VERDES</h5>
                    </div>               
                    
                    
                    
                    
                    
                    
                </div>
            </div>
        </section>
    )
}

export default Servicos