import React from 'react';
import './App.css';
import 'jquery/dist/jquery.min.js';
import 'font-awesome/css/font-awesome.min.css'; // Atualizado para a forma correta
import Header from '../layout/header';
import Content from '../layout/content';
import Footer from '../layout/footer';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Content />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
