import React from 'react'

const Informacoes = (props) => {

    return(
        <div className="Informacoes">
            <h1>DPO - Data Protection Officer</h1>
            <div className="line"></div>
            <div className="infos">
            <div className="box">
                    <i className="fas fa-user"></i><span>Stepherson Junior de Assis Faria</span>
                </div>
                <div className="box">
                    <i className="fas fa-envelope"></i><span>dpo@sotecplast.com.br</span>
                </div>
                <div className="box">
                    <i className="fas fa-phone-alt"></i><span>(12) 99678-1314</span>
                </div>
                
            </div>
            
        </div>
    )
}

export default Informacoes