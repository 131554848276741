import React, {useState} from 'react'
import './Contato.css'
import Topo from '../../components/Topo'

import Trabalhe from './components/Trabalhe'
import FormContato from './components/FormContato'
import Informacoes from './components/Informacoes'
import Fornecedor from './components/Fornecedor'
import TextContato from '../../components/TextContato'

const Contato = (props) => {
    const [menu, setMenu] = useState(1)
    function OpenArea(){
        window.open('https://webmail-seguro.com.br/sotecplast.com.br/')
    }
    const Forms = () => {
        return(
                <div className="forms" id="forms">
                    <div className={ menu === 1 ? "show" : "hide" }>
                        <Informacoes />
                    </div>
                    <div className={ menu === 2 ? "show" : "hide" }>
                        <FormContato />
                    </div>
                    <div className={ menu === 3 ? "show" : "hide" }>
                        <Trabalhe />
                    </div>
                    <div className={ menu === 4 ? "show" : "hide" }>
                        <Fornecedor />
                    </div>
                </div>
        )
    }

    return(
        <section id="Contato" className="Contato">
            <Topo
                img="contato"
                page="contato"
                
            />
            <TextContato title="FALE CONOSCO" forms={Forms()}>
                <p>Entre em contato conosco, conheça melhor nossos serviços e entenda como a nossa equipe pode te ajudar.</p>
                <div className="Buttons">
                    <button onClick={()=>setMenu(1)}><i className="fas fa-map-marker-alt"></i><span>Informações</span></button>
                    <button onClick={()=>setMenu(2)}><i className="fas fa-mail-bulk"></i><span>Fale Conosco</span></button>
                    <button onClick={()=>setMenu(3)}><i className="fas fa-paste"></i><span>Trabalhe Conosco</span></button>
                    <button onClick={()=>setMenu(4)}><i className="fas fa-paste"></i><span>Solicite um orçamento</span></button>
                    <button onClick={OpenArea}><i className="fas fa-user-tie"></i><span>Área do funcionário</span></button>
                </div>
            </TextContato>
            
        </section>
    )
}

export default Contato