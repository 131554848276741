import React from 'react'
import './Sustentabilidade.css'
import Topo from '../../components/Topo'
import TextField from '../../components/TextField'

const Sustentabilidade = (props) => {

    return(
        <section id="Sustentabilidade" className="Sustentabilidade">
            <Topo
                page="sustentabilidade"
                
            />
            <TextField title="NOSSO COMPROMISSO" img="sustentabilidadeFoto">
                <p>Somos comprometidos com a comunidade e com o meio ambiente, entendendo assim a importância de preserva-lo, através de alternativas sustentáveis, desenvolvendo a conscientização nos colaboradores e parceiros, sempre com transparência e boa comunicação.</p>
                <p>Nas rotinas, aplicamos ações simples que fazem a diferença, tais como: o programa “ao sair, desligue”, o descarte correto de resíduos e outros; focando os aspectos, econômico, social e ambiental.</p>
                <p>Os produtos que utilizamos nos processos de higienização dos locais, peças, utensílios e componentes são biodegradáveis. O lixo gerado é destinado ao processo de coleta seletiva, com o objetivo de reaproveitar cada tipo de material segregado.</p>
            </TextField>
                         </section>
    )
}

export default Sustentabilidade