import React from 'react'
import './Pessoas.css'
import Topo from '../../components/Topo'
import TextField from '../../components/TextField'
import SecondaryText from '../../components/SecondaryText'
import Codigo from '../../imgs/Código de Ética Sotecplast.pdf'

const Pessoas = (props) => {

    return(
        <section id="Pessoas" className="Pessoas">
            <Topo
                page="pessoas"
                         />
            <TextField title="GESTÃO DE PESSOAS" img="pessoasFoto">
                <p className="pfirst">Entendemos que o desenvolvimento só é possível com a cooperação de todos, assumindo o compromisso de valorizar a integridade, o respeito e a segurança em busca da qualidade de vida no trabalho.</p>
                
                <p className="pfirst">Investimos em nossos profissionais, proporcionando um ambiente de trabalho tranquilo, oferecendo capacitação e oportunidade de desenvolvimento, estimulando a motivação e o bem-estar para que todos trabalhem com eficiência.</p>
                <p className="plast">Buscamos gestão participativa com canal direto de comunicação e estruturas descentralizadas, focando no relacionamento cooperativo como ferramenta para superar conflitos internos e promover mudanças nas relações do trabalho.</p>
           <br></br>
           <br></br>
            </TextField>
            <SecondaryText img="responsabilidade"><h3>Responsabilidade Social Corporativa (RSC)</h3>
                <p>Nós somos voltados à gestão sustentável em termos econômicos, ecológicos e sociais. Buscamos a integração voluntária de nossos cooperadores com as integrações da comunidade e do meio ambiente em nossas frentes de trabalho:</p>
                <ul>
                    <li>•	Ações comunitárias, atendimento às normas ambientais e à comunidade como um todo;</li>
                    <li>•	Contratação de fornecedores locais, contribuindo com o desenvolvimento econômico regional;</li>
                    <li>•	A contratação de mão de obra é direcionada para a comunidade local, chegando a atingir 95% em certas frentes, beneficiando a empregabilidade e inserindo-a no mercado de trabalho. Os colaboradores sem experiência são treinados pela Sotecplast, proporcionando crescimento futuro, desenvolvimento e qualificação da equipe.</li>
                </ul>
            </SecondaryText>
            
            <SecondaryText img="etica"> <h3>Código de Ética </h3>
                <p>O nosso código de Ética e Conduta é um instrumento oficial que tem o objetivo de estabelecer princípios relativos às negociações e à conduta dos nossos colaboradores.</p>
                <p>Os procedimentos descritos neste documento estão sob respaldo da legislação vigente no país e em constante aprimoramento. Os pontos abordados tratam das nossas ações com clientes, fornecedores, colaboradores, órgãos públicos, mídias, entidades e a comunidade em geral.</p>
                <br></br>
                <p><a href={Codigo} target="_BLANK" rel="noreferrer">Clique aqui para visualizar nosso código de ética.</a></p>
                <br></br>
                <br></br>
               
            
            </SecondaryText>   

            <div className="objetivos">
                <h1>TREINAMENTOS</h1>
                <div className="cardsItems">
                <div className="Item item1">
                   
                   
                </div>
                <div className="Item item2">
                
                </div>
                <div className="Item item3">
               
                </div>
                <div className="Item item4">
              
                </div>
                
                </div>
            </div>
        </section>


    )
}

export default Pessoas