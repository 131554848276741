import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import './TabTimeline.css'
import Img1968 from '../imgs/tab1968.jpg'
import Img1975 from '../imgs/tab1975.jpg'
import Img1977 from '../imgs/tab1977.jpg'
import Img1984 from '../imgs/tab1984.jpg'
import Img2000 from '../imgs/tab2000.jpg'
import Img2018 from '../imgs/tab2018.jpg'
import Img2018b from '../imgs/tab2018-2.jpg'
import foto2019 from '../imgs/foto2019.jpeg'
import foto2019b from '../imgs/foto2019b.jpeg'
import foto2021 from '../imgs/foto2021.jpeg'
import foto2021b from '../imgs/tab2018-2-1-a.jpg'

const TabTimeline = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  function controlsBefore(){
    setActiveTab((parseInt(activeTab) - 1).toString())
  }

  function controlsAfter(){
    setActiveTab((parseInt(activeTab) + 1).toString())
  }

  console.log(activeTab)
  return (
    <div className="tabTimeline">
      <div className="tabs">
        <button
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
        >
            {activeTab === '1' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            <span>1968</span>
        </button>
        <button
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
        >
            {activeTab === '2' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            1970
        </button>
        <button
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
        >
            {activeTab === '3' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            1975
        </button>
        <button
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
        >
            {activeTab === '4' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            1977
        </button>
        <button
            className={classnames({ active: activeTab === '5' })}
            onClick={() => { toggle('5'); }}
        >
            {activeTab === '5' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            1984
        </button>
        <button
            className={classnames({ active: activeTab === '6' })}
            onClick={() => { toggle('6'); }}
        >
            {activeTab === '6' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2000
        </button>
        <button
            className={classnames({ active: activeTab === '7' })}
            onClick={() => { toggle('7'); }}
        >
            {activeTab === '7' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2004
        </button>
        <button
            className={classnames({ active: activeTab === '8' })}
            onClick={() => { toggle('8'); }}
        >
            {activeTab === '8' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2005
        </button>
        <button
            className={classnames({ active: activeTab === '9' })}
            onClick={() => { toggle('9'); }}
        >
            {activeTab === '9' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2006
        </button>
        <button
            className={classnames({ active: activeTab === '10' })}
            onClick={() => { toggle('10'); }}
        >
            {activeTab === '10' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2008
        </button>
        <button
            className={classnames({ active: activeTab === '11' })}
            onClick={() => { toggle('11'); }}
        >
            {activeTab === '11' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2009
        </button>
        <button
            className={classnames({ active: activeTab === '12' })}
            onClick={() => { toggle('12'); }}
        >
            {activeTab === '12' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2016
        </button>
        <button
            className={classnames({ active: activeTab === '13' })}
            onClick={() => { toggle('13'); }}
        >
            {activeTab === '13' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2018
        </button>
        <button
            className={classnames({ active: activeTab === '14' })}
            onClick={() => { toggle('14'); }}
        >
            {activeTab === '14' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2019
        </button>
        <button
            className={classnames({ active: activeTab === '15' })}
            onClick={() => { toggle('15'); }}
        >
            {activeTab === '15' ? <div className="marcado"></div> : <div className="naomarcado"></div>}
            2021
        </button>
      </div>
        
      <TabContent activeTab={activeTab} className="contentTab">
        <div className="controls">
            {
                activeTab === '1' ? <div></div> :
                    <button onClick={controlsBefore}>
                        <i className="fas fa-caret-left"></i>
                    </button>
            }
            {
                activeTab === '15' ? <div></div> :
                    <button onClick={controlsAfter}>
                        <i className="fas fa-caret-right"></i>
                    </button>
            }
        </div>
        <TabPane className="pane" tabId="1">
            <h2>1968</h2>
            <p>A Sotecplast é fundada em Petrópolis (RJ), sendo empresa pioneira em preparação e revestimento de superfícies.</p>
            <img className="imgPane" src={Img1968} alt="1968" />
        </TabPane>
        <TabPane className="pane" tabId="2">
            <h2>1970</h2>
            <p>Para atender a seus clientes com mais eficiência, Sotecplast se muda para a cidade do Rio de Janeiro (RJ), onde passa a se dedicar à área de construção naval, setor que na época, se encontra em franca expansão.</p>
        </TabPane>
        <TabPane className="pane" tabId="3">
            <h2>1975</h2>
            <p>A Sotecplast desenvolve uma técnica especial, única em toda a América do Sul, visando um atendimento rápido, seguro e sem prejuízo para os Armadores. Tal procedimento permite que ela participe de todos os planos nacionais de desenvolvimento naval e atue, praticamente, em todas as embarcações construídas no Brasil.</p>
            <img className="imgPane" src={Img1975} alt="1975" />
        </TabPane>
        <TabPane className="pane" tabId="4">
            <h2>1977</h2>
            <p>Dando prosseguimento aos seus serviços técnicos, a Sotecplast conquista novos clientes e expande sua área de atuação.</p>
            <img className="imgPane" src={Img1977} alt="1977" />
        </TabPane>
        <TabPane className="pane" tabId="5">
            <h2>1984</h2>
            <p>Com o declínio acentuado da atividade da industria naval, Sotecplast desenvolve esquemas de proteção para atendimento às áreas industrial e civil.</p>
            <img className="imgPane" src={Img1984} alt="1984" />
        </TabPane>
        <TabPane className="pane" tabId="6">
            <h2>2000</h2>
            <p>A empresa estabelece sua sede administrativa na cidade de Taubaté (SP), atingindo a marca de 3.000.000 metros quadrados de superfícies revestidas.</p>
            <img className="imgPane" src={Img2000} alt="2000" />
        </TabPane>
        <TabPane className="pane" tabId="7">
            <h2>2004</h2>
            <p>Sotecplast inicia o projeto de manutenção das barcaças da Cia de navegação Norsul no Terminal portuário de Portocel, situado na cidade de Aracruz-ES.</p>
        </TabPane>
        <TabPane className="pane" tabId="8">
            <h2>2005</h2>
            <p>Em busca de novos negócios, Sotecplast expande seus serviços de tratamento anticorrosivo, revestimento e caldeiraria leve, para o setor sucroenergético, segmento carente de firmas especializadas.</p>
        </TabPane>
        <TabPane className="pane" tabId="9">
            <h2>2006</h2>
            <p>Iniciamos o fornecimento de mão de obra especializada para embarcações de longo curso, realizando os serviços com as equipes embarcadas em viagens internacionais (EUA, Canadá, Europa e Ásia).</p>
        </TabPane>
        <TabPane className="pane" tabId="10">
            <h2>2008</h2>
            <p>Em 2008, passamos a fornecer a Gestão de facilities no Terminal Marítimo de Belmonte, onde além dos serviços tradicionais oferecidos, destacam-se os serviços especializados de manutenção preventiva de eletromecânica e caldeiraria leve.</p>
        </TabPane>
        <TabPane className="pane" tabId="11">
            <h2>2009</h2>
            <p>Sotecplast agrega ás suas atividades os serviços de:</p>
            <p>• Operação portuária – Para embarcações de diversas tonelagens.</p>
            <p>• Hidrojateamento UAP / SAP – Para serviços de tratamento anticorrosivo e desobstrução de tubulações.</p>
            <p>Iniciando o fornecimento de mão de obra especializada em São Francisco do Sul-SC.</p>
        </TabPane>
        <TabPane className="pane" tabId="12">
            <h2>2016</h2>
            <p>Ultimamente a Sotecplast tem investido em novos procedimentos logísticos, de modo a atender com mais objetividades a seus clientes. Seja no que concerne treinamento de nossos colaboradores, seja na busca de inovações em tecnologia para seus processos.</p>
        </TabPane>
        <TabPane className="pane" tabId="13">
            <h2>2018</h2>
            <div className="tab2018content">
                <div className="tab2018a">
                    <p>É com grande satisfação que a Sotecplast comemora meio século de tradição, inovação e qualidade.</p>
                    <p>A Sotecplast é pioneira em seu ramo de atuação e, por isso mesmo, trabalha para um desenvolvimento atrelado a inovação e qualidade, buscando atitudes sustentáveis e um trabalho conectado com as pessoas que constroem nossa história.</p>
                    <img className="imgPane" src={Img2018} alt="2018" />
                </div>
                <div className="tab2018b">
                    <p>Com o objetivo de aumentar a satisfação do cliente por meio de melhorias de processo e avaliação da conformidade, em Agosto de 2018 a SOTECPLAST certificou o seu sistema de gestão da qualidade, de acordo com os requisitos da ISO 9001:2015, comprovando a sua capacidade de fornecer produtos e serviços que atendem às necessidades de seus clientes e requisitos legais e regulatórios aplicáveis.</p>
                    <img className="imgPane" src={Img2018b} alt="2018-b" />
                </div>
            </div>
        </TabPane>
        <TabPane className="pane" tabId="14">
            <h2>2019</h2>
            <div className="tab2021content">
                <div className="tab2021a">
                    <p>Fornecimento de veículos.</p>
                  
                    <img className="imgPane" src={foto2019} alt="2019-a" />
                </div>
                <div className="tab2021b">
                    <p>Equipamentos para apoio logístico.</p>
                    <img className="imgPane" src={foto2019b} alt="2019-b" />
                </div>
            </div>
            
        </TabPane>

        <TabPane className="pane" tabId="15">
            <h2>2021</h2>
            <div className="tab2021content">
                <div className="tab2021a">
                    <p>Início das obras do galpão no Espírito Santo.</p>
                  
                    <img className="imgPane" src={foto2021} alt="2021-a" />
                </div>
                <div className="tab2021b">
                    <p>Recertificação da ISO 9001</p>
                    <img className="imgPane" src={foto2021b} alt="2021-b" />
                </div>
            </div>
            
        </TabPane>
        
      </TabContent>
    </div>
  );
}

export default TabTimeline;