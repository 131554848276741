import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import './Carousel.css'

const items = [
  {
    id: 1,
    title: 'Slide 1',
    altText: 'Slide 1',
  },
  {
    id: 2,
    title: 'Slide 2',
    altText: 'Slide 2',
    
  },
  {
    id: 3,
    title: 'Slide 3',
    altText: 'Slide 3',
    
  },
  {
    id: 4,
    title: 'Slide 4',
    altText: 'Slide 4',
  
  },
  {
    id: 5,
    title: 'Slide 5',
    altText: 'Slide 5',
    
  },
  {
    id: 6,
    title: 'Slide 6',
    altText: 'Slide 6',
   
  }
  
];

const CarouselHome = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item, i) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <div className={"fundogaleria img" + i}>
        </div>
          <CarouselCaption className="textoGaleria" captionText={item.captionText} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className="carouselHome"
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl className="prev" direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl className="next" direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default CarouselHome;