import React from 'react'
import './Form.css'
import emailjs from "emailjs-com"


const FormContato = (props) => {
    
  
    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('service_671wkeh', 'template_c21ogda', e.target, 'user_JeiscEdsWUEiz3QNgY2ZC')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

    return(
        <div className="FormContato">
                 <form onSubmit={sendEmail}>
            <h1>Fale com o DPO</h1>
            <div className="line"></div>
           
                <label htmlFor="name">Nome</label>
                <input type="text" name="name" />
                <label htmlFor="email">Email</label>
                <input type="text" name="email" />
                <label htmlFor="phone">CPF</label>
                <input type="text" name="cpf" />
                <select name="categoria" class="form-control">
                        
											<option value="categoria" className="label" selected>Selecione a categoria da solicitação</option>
											<option value="1. Anonimização">1. Anonimização</option>
											<option value="2. Confirmação da existência de tratamento">2. Confirmação da existência de tratamento</option>
											<option value="3. Correção de dados">3. Correção de dados</option>
											<option value="4. Compartilhamento de dados">4. Compartilhamento de dados</option>
											<option value="5. Remoção de dados">5. Remoção de dados</option>
											<option value="6. Outro">6. Outro</option>
										</select>
                                <label htmlFor="subject">Mensagem</label>
                <textarea name="message" id="" cols="30" rows="10" />
                <button type="submit">
                    Enviar
                </button>
            </form>
        </div>
    )
}

export default FormContato