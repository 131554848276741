import React, { useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import Iso from '../imgs/iso.jpg'
import './ToggleIso.css'
import DocIso from '../imgs/Certificado_ISO_9001.pdf'

const ToggleIso = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div className="toogleIsoDiv">
      <button id="Popover1" type="button" className="toggleIso">
        <img src={Iso} alt="" className="iso" />
      </button>
      <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
        <PopoverBody>
          <p>A ISO 9001 é um sistema de gestão com o intuito de garantir a otimização de processos, maior agilidade no desenvolvimento de produtos e produção mais ágil a fim de satisfazer os clientes e alcançar o sucesso sustentado.</p>
          <a href={DocIso} target="_BLANK" rel="noreferrer">Veja nosso certificado</a>
        </PopoverBody>
      </Popover>
    </div>
  );
}

export default ToggleIso;