import React from 'react';
import ToggleMenu from '../components/ToggleMenu';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Switch, Route, Redirect } from 'react-router-dom'; // Importar Redirect

import Institucional from '../pages/institucional/Institucional';
import Pessoas from '../pages/pessoas/Pessoas';
import Seguranca from '../pages/seguranca/Seguranca';
import Servicos from '../pages/servicos/Servicos';
import Sustentabilidade from '../pages/sustentabilidade/Sustentabilidade';
import LGPD from '../pages/LGPD/LGPD';
import Contato from '../pages/contato/Contato';
import Transparencia from '../pages/portal/portal';
import Home from '../pages/home/Home';

const Content = (props) => {
    const WPP = () => {
        window.open("https://api.whatsapp.com/send?phone=551236338089");
    };

    const LKI = () => {
        window.open("https://www.linkedin.com/company/sotecplast-ltda/");
    };

    return (
        <div className="content">
            <div className="toggleMenu">
                <ToggleMenu />
            </div>
            
            <div className="btTop">
                <button className="linkedinButton" onClick={LKI}>
                    <i className="fab fa-linkedin-in"></i>
                </button>
                <button className="whatsButton" onClick={WPP}>
                    <i className="fab fa-whatsapp whats"></i>
                </button>
            </div>

            <Switch>
                <Route path="/contato" component={Contato} />
                <Route path="/sustentabilidade" component={Sustentabilidade} />
                <Route path="/servicos" component={Servicos} />
                <Route path="/seguranca" component={Seguranca} />
                <Route path="/pessoas" component={Pessoas} />
                <Route path="/institucional" component={Institucional} />
                <Route path="/LGPD" component={LGPD} />
                <Route path="/portal" component={Transparencia} /> {/* Corrigido para "/portal" */}
                <Route exact path="/" component={Home} /> {/* Rota padrão */}
                <Redirect to="/" /> {/* Redireciona qualquer caminho não definido para a Home */}
            </Switch>
        </div>
    );
};

export default Content;
