import React from 'react';
import Logo from '../imgs/LOGO.png';
import LogoB from '../imgs/LOGOB.png';
import Iso from '../components/ToggleIso'; // Certifique-se de que o caminho está correto
import { Link } from 'react-router-dom';

const Header = (props) => {
    return (
        <nav className="header">
            <div className="logo">
                <Link to="/"><img src={Logo} alt="logo" className="logo" /></Link>
            </div>
            <div className="logob">
                <Link to="/"><img src={LogoB} alt="logo" className="logob" /></Link>
            </div>
            <ul className="menu">
                <div className="menuGrande menuHidden">
                    <Link to="/"><li>Home</li></Link>
                    <Link to="/institucional"><li>Sobre</li></Link>
                    <Link to="/pessoas"><li>Pessoas</li></Link>
                    <Link to="/seguranca"><li>Segurança</li></Link>
                    <Link to="/servicos"><li>Serviços</li></Link>
                    <Link to="/sustentabilidade"><li>Sustentabilidade</li></Link>
                    <Link to="/LGPD"><li>LGPD</li></Link>
                    <Link to="/contato"><li>Contato</li></Link>
                    <Link to="/portal"><li>Transparência</li></Link>

                    {/* Adicionando o componente Iso */}
                    <div className="toogleIsoDiv">
                        <Iso />
                    </div>
                </div>
            </ul>
        </nav>
    );
}

export default Header;
