import React, { useState } from 'react';
import './LGPD.css';
import Topo from '../../components/Topo';
import FormContato from './componentes/FormContato';
import Informacoes from './componentes/Informacoes';
import TextContato from '../../components/TextContato';
import Docpol from '../../imgs/politica_privacidade.pdf';

const LGPD = (props) => {
    const [menu, setMenu] = useState(1);

    // Remova ou use OpenArea se necessário
   
    

    // Componente para exibir formulários
    const Forms = () => (
        <div className="forms" id="forms">
            <div className={menu === 1 ? "show" : "hide"}>
                <Informacoes />
            </div>
            <div className={menu === 2 ? "show" : "hide"}>
                <FormContato />
            </div>
        </div>
    );

    return (
        <section id="lgpd" className="lgpd">
            <Topo
                img="lgpd"
                page="LGPD"
            />
            <TextContato title="CONTATO COM O DPO" forms={<Forms />}>
                <p>
                    Canal exclusivo para o exercício de seus direitos com relação à Lei Geral de Proteção de Dados (13.709/2018) - LGPD.
                </p>
                <div className="Buttons">
                    <button onClick={() => setMenu(1)}>
                        <i className="fas fa-user"></i>
                        <span>Nosso DPO</span>
                    </button>
                    <button onClick={() => setMenu(2)}>
                        <i className="fas fa-mail-bulk"></i>
                        <span>Formulário de contato</span>
                    </button>
                </div>
                <br />
                <p>
                    <a href={Docpol} target="_blank" rel="noreferrer">Conheça a nossa Política de Privacidade</a>
                </p>
            </TextContato>
        </section>
    );
};

export default LGPD;
