import React from 'react'
import './Seguranca.css'
import Topo from '../../components/Topo'
import TextField from '../../components/TextField'

const Seguranca = (props) => {

    return(
        <>
        <section id="Seguranca" className="Sustentabilidade">
            <Topo
                page="seguranca"
                        />
            <TextField title="SAÚDE, SEGURANÇA E MEIO AMBIENTE" img="segurancaFoto">
            <p>Para a Sotecplast, saúde, segurança e meio ambiente, é coisa séria!</p>
                <p>Nós prezamos a saúde e integridade física dos nossos colaboradores através de práticas de trabalho seguras e saudáveis.</p>
                <p>Nossa equipe tem o compromisso de seguir criteriosamente as normas de segurança e saúde, executando suas atividades dentro dos princípios da melhoria contínua. </p>
                <p>Assumimos este compromisso!</p>
                <p>Fazer a diferença é o que nos move.</p>
              
                             
            </TextField>
            
            <div className="objetivos">
                
                <div className="cardsItems">
                <div className="Item item1">
                   
                   
                </div>
                <div className="Item item2">
                
                </div>
                <div className="Item item3">
               
                </div>
                <div className="Item item4">
              
                </div>
               
                </div>
            </div>

            
          
        </section>
        
        <div className="space"></div>
        </>
    )
}

export default Seguranca