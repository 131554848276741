import React from 'react'
import './Form.css'
import emailjs from "emailjs-com"


const FormContato = (props) => {
    
  
    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('service_idsotecplast', 'template_2g0njiq', e.target, 'user_GmC2GimPuou8Oxu81TRAN')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

    return(
        <div className="FormContato">
                 <form onSubmit={sendEmail}>
            <h1>Fale Conosco</h1>
            <div className="line"></div>
           
                <label htmlFor="name">Nome</label>
                <input type="text" name="name" />
                <label htmlFor="email">Email</label>
                <input type="text" name="email" />
                <label htmlFor="phone">Telefone</label>
                <input type="text" name="phone" />
                <label htmlFor="subject">Assunto</label>
                <input type="text" name="subject" />
                <label htmlFor="subject">Mensagem</label>
                <textarea name="message" id="" cols="30" rows="10" />
                <button type="submit">
                    Enviar
                </button>
            </form>
        </div>
    )
}

export default FormContato