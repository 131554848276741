import React from 'react'

const Informacoes = (props) => {

    return(
        <div className="Informacoes">
            <h1>Informações</h1>
            <div className="line"></div>
            <div className="infos">
                <div className="box">
                    <i className="fas fa-envelope"></i><span>comercial@sotecplast.com.br</span>
                </div>
                <div className="box">
                    <i className="fas fa-phone-alt"></i><span>(12) 3633-8089 / 3624-4832 / 3424-3027</span>
                </div>
                <div className="box">
                    <i className="fas fa-map-marker-alt"></i><span>Rua Joaquim Tavares, 137, Santa Rita,12020-280 -Taubaté/SP</span>
                </div>
            </div>
            
        </div>
    )
}

export default Informacoes