import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../imgs/LOGO.png';
import './footer.css';
import Docpol from '../imgs/politica_privacidade.pdf';

function Footer(props) {
    return (
        <div className="footer">
            <div className="footerContent">
                <div className="imgfooter">
                    <img src={Logo} alt="Logo" className="logofooter" />
                </div>
                
                <div className="box box1">
                    <h3>Empresa</h3>
                    <ul>
                        <li><Link to="/institucional">- A Sotecplast</Link></li>
                        <li><Link to="/pessoas">- Pessoas</Link></li>
                        <li><Link to="/seguranca">- Segurança</Link></li>
                        <li><Link to="/sustentabilidade">- Sustentabilidade</Link></li>
                        <li><Link to="/portal">- Portal da Transparência</Link></li>
                        <li><a href={Docpol} target="_BLANK" rel="noreferrer">- Política de Privacidade</a></li>
                    </ul>
                </div>
                <div className="box box2">
                    <h3>Serviços</h3>
                    <ul>
                        <li><Link to="/servicos">- Preparação de Superfícies</Link></li>
                        <li><Link to="/servicos">- Revestimento de Superfícies</Link></li>
                        <li><Link to="/servicos">- Apoio Portuário</Link></li>
                        <li><Link to="/servicos">- Solda e Caldeiraria</Link></li>
                        <li><Link to="/servicos">- Fornecimento de Veículos</Link></li>
                        <li><Link to="/servicos">- Eletromecânica</Link></li>
                        <li><Link to="/servicos">- Limpeza Técnica</Link></li>
                        <li><Link to="/servicos">- Conservação de Áreas Verdes</Link></li>
                    </ul>
                </div>
                <div className="box box3">
                    <h3>Fale conosco</h3>
                    <ul>
                        <li><Link to="/contato"><i className="fas fa-envelope"></i> comercial@sotecplast.com.br</Link></li>
                        <li><Link to="/contato"><i className="fas fa-phone-alt"></i> (12) 3633-8089 / 3624-4832 / 3424-3027</Link></li>
                        <li><Link to="/contato"><i className="fas fa-map-marker-alt"></i> Rua Joaquim Tavares, 137, Santa Rita, 12020-280 - Taubaté/SP</Link></li>
                    </ul>
                </div>
            </div>
            
            <div className="lgpd">
                <a href={Docpol} target="_BLANK" rel="noreferrer">Conheça a nossa Política de Privacidade</a>
                <div className="dedicatoria">
                    <p>© 2022 - Sotecplast</p>
                    <p>Desenvolvido por <a href="http://www.unixtechti.com.br/" target="_blank" rel="noreferrer">UnixtechTI</a></p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
