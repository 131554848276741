import React from 'react'
import './TextField.css'

const TextField = (props) => {

    return(
        <div className="TextField">
            <div className="title">
                <h1 className={props.img}>{props.title} <span>{props.title2}</span></h1>
            </div>
            <div className="content">
                <div className="text">
                    {props.children}
                </div>
                <div className={"imgText " + props.img}></div>
            </div>
        </div>
    )
}

export default TextField