import React from 'react'
import './Institucional.css'
import Topo from '../../components/Topo'
import TextField from '../../components/TextField'
import TabTimeline from '../../components/TabTimeline'
import SecondaryText from '../../components/SecondaryText'
import IsoPDF from '../../imgs/Certificado_ISO_9001.pdf'

const Institucional = (props) => {

    return(
        <section id="Institucional" className="Institucional">
            <Topo
                page="institucional"
                 />
            <TextField title="SOBRE NÓS" img="institucionalFoto">
                <p className="pfirst">Somos uma empresa Brasileira que atua no mercado desde 1968, realizando serviços de preparação de superfícies, revestimentos, apoio portuário, solda e caldeiraria, manutenção eletromecânica e gestão de facilities (fornecimento de veículos, limpeza técnica e conservação de áreas verdes), atuando em todo o Território Nacional.</p>
                <p className="pfirst">Focamos na evolução constante da qualidade, através da divulgação de nossos princípios e da participação dos colaboradores em processos de mudança na cultura gerencial, mantendo desta forma a excelência em serviços, atendendo aos requisitos ambientais, de sustentabilidade, segurança e saúde, sempre com ética e transparência em todas as ações.</p>
                <p className="plast">Acreditamos que ações de gestão transparentes e transformadoras motivam nossa equipe a trabalhar de maneira honesta em busca de qualidade de vida, convívio harmonioso, geração de alternativas sustentáveis e de responsabilidade social.</p>
            </TextField>
            <div className="mvv">
                <div className="mvvbox missao">
                    <h3>MISSÃO</h3>
                    <p>Ser referência nacional em prestação de serviços e soluções inovadoras no segmento que atuamos, atendendo sempre com excelência às necessidades e expectativas de nossos clientes pautados nos nossos valores, buscando sinergia para um sólido e duradouro relacionamento de parceria.</p>
                </div>
                <div className="mvvbox visao">
                    <h3>VISÃO</h3>
                    <p>Realizar projetos e serviços com excelência superando as expectativas dos clientes, ampliando a produtividade, aprimorando as técnicas estruturais e comerciais, inovando no nosso segmento, com responsabildiade, confiabilidade, integridade e segurança, objetos determinantes no fortalecimento da marca.</p>
                </div>
                <div className="mvvbox valores">
                    <h3>VALORES</h3>
                    <ul>
                        <li>- Comprometimento</li>
                        <li>- Transparência</li>
                        <li>- Qualidade</li>
                        <li>- Inovação</li>
                        <li>- Segurança</li>
                        <li>- Ética</li>
                        <li>- Meio Ambiente</li>
                    </ul>
                </div>
            </div>
            <div className="timeline">
                <h1>Nossa História</h1>
                <TabTimeline />
            </div>
            <SecondaryText img="gestaoQualidade"> <h3>Gestão de Qualidade</h3>
                <p>Com o objetivo de melhor atender nossos clientes, o nosso sistema de gestão da qualidade foi certificado de acordo com os requisitos da ISO 9001:2015, comprovando a nossa capacidade de fornecer produtos e serviços que atendem às necessidades dos nossos clientes, requisitos legais e regulatórios aplicáveis.</p>
                <p>Visando a eficácia do nosso sistema de gestão da qualidade, nós analisamos e documentamos com clareza e objetividade, os nossos processos e procedimentos reforçando o nosso comprometimento com nossos clientes.</p>
                <p><a href={IsoPDF} target="_BLANK" rel="noreferrer">Clique aqui para visualizar nosso certificado.</a></p>
            </SecondaryText>
            <SecondaryText img="politicaQualidade"> <h3>Política de Qualidade</h3>
                <p>Estabelecemos, através desta política da qualidade, o nosso comprometimento com a satisfação e superação das expectativas do cliente, através das seguintes ações:</p>
                <ul>
                    <li>1. Manter a excelência em serviços de operação e manutenção, capacitando e valorizando nossos colaboradores, e monitorando fornecedores;</li>
                    <li>2. Atender aos requisitos legais, contratuais e técnicos;</li>
                    <li>3. Melhorar continuamente a eficácia do sistema de gestão da qualidade e atender seus objetivos;</li>
                    <li>4. Utilizar as boas práticas durante a execução dos nossos serviços.</li>
                </ul>
            </SecondaryText>   

            <div className="objetivos">
                <h1>NOSSOS CERTIFICADOS</h1>
                <div className="area">
                <div className="Item item11">
                   
                   
                </div>
                <div className="Item item12">
                
                </div>
                <div className="Item item13">
               
                </div>
               
                <div className="Item item5">
                
                </div>
                
                <div className="Item item6">
             
                </div>
                <div className="Item item7">
               
                </div>
                
                


                </div>
            </div>
        </section>
    )
}

export default Institucional