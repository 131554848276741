import React, { useState } from 'react';
import { Collapse, Button } from 'reactstrap';
import './ToggleMenu.css'
import { Link } from "react-router-dom";

const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="ToggleButton">
      <Button onClick={toggle} style={{ background: "#1c417b" }}>
        <i className="fas fa-bars"></i>
      </Button>
      <Collapse isOpen={isOpen}>
        
            <div className="menuGrande menuToggle" onClick={()=> setIsOpen(false)}>
              <ul>
               <Link to="/home"><li>Home</li></Link>
                <Link to="/institucional"><li>Sobre</li></Link>
                <Link to="/pessoas"><li>Pessoas</li></Link>
                <Link to="/seguranca"><li>Segurança</li></Link>
                <Link to="/servicos"><li>Serviços</li></Link>
                <Link to="/sustentabilidade"><li>Sustentabilidade</li></Link>
                <Link to="/LGPD"><li>LGPD</li></Link>
                <Link to="/contato"><li>Contato</li></Link>
                <Link to="/Portal"><li>Portal</li></Link>
              </ul>
            </div>
      </Collapse>
    </div>
  );
}

export default Example;